<template>
  <main v-if="offer" id="form-page-next" class="next-container">
    <section-base>
      <div class="heading-col without-margin">
        <h1 class="color-primary-100">{{ text.titleH1 }}</h1>

        <h2 class="color-primary-100">{{ text.titleH2 }}</h2>
      </div>

      <cta-base>
        <p class="cta-block-inner-text" v-html="text.titleCta"></p>

        <cta-list-base>
          <cta-list-item
            v-for="(item, idx) in text.listCta"
            :key="idx"
            :title="item"
            class="text-style-medium"
          />
        </cta-list-base>

        <div class="cta-block-button">
          <button-regular class="button-accent" @click="scrollAction">
            {{ text.buttonCta }}
          </button-regular>
        </div>
      </cta-base>
    </section-base>

    <section-base id="offer_packages">
      <div class="heading-col">
        <h1 class="color-primary-100 mobile-h2">
          {{
            getPackagesCount() === 1
              ? text.packagesOneTitle
              : text.packagesTitle
          }}
        </h1>

        <p
          class="color-accent-300 text-subtitle"
          v-html="text.packagesSubtitle"
        ></p>
      </div>

      <cta-product-base
        v-for="(product, idx) in offer.packages"
        :key="idx"
        :product="product"
        :showButton="true"
        page="archive"
        :img="`product-image-${idx}`"
      />

      <div class="under-text">
        <small class="text-small color-accent-300">
          <sup>1</sup> Angaben sind geschätzt, basierend auf Ihren
          zurückliegenden Verbrauchsdaten gemäß Ihrer letzten Jahresrechnung.
        </small>

        <small class="text-small color-accent-300">
          <sup>2</sup> Sollten Sie sich für eine Ratenzahlung entscheiden, wird
          Ihnen eine Ratenzahlungsvereinbarung mit einem Ratenzahlungsplan in
          Ihr mein eprimo Postfach übersandt, aus dem Sie die jeweiligen
          Fälligkeiten entnehmen können. Die Gewährung der Ratenzahlung erfolgt
          zinsfrei über die von Ihnen gewählte Anzahl von Raten. Weitere Details
          entnehmen Sie bitte den hier einsehbaren
          <a
            :href="
              `${publicPath}version3/agb_ergaenzende_Bedingungen_Strompaket_220621.pdf`
            "
            target="_blank"
            >Strompakete-AGB</a
          >.
        </small>

        <small class="text-small color-accent-300">
          <sup>3</sup> <span v-html="text.footnote3"></span>
        </small>
      </div>
    </section-base>

    <section-base>
      <div class="heading-col">
        <h1 class="color-primary-100 mobile-h2">
          Die eprimo Strompakete kurz erklärt
        </h1>
      </div>

      <main-video />
    </section-base>

    <section-base>
      <accordion-faq>
        <div class="heading-col" slot="title">
          <h1 class="color-primary-100 mobile-h2">Haben Sie Fragen?</h1>
        </div>
      </accordion-faq>
    </section-base>
  </main>
</template>

<script>
import SectionBase from '@/components/next/section-base.vue';
import ButtonRegular from '@/components/next/button-regular.vue';
import CtaBase from '@/components/next/cta/cta-base.vue';
import CtaListItem from '@/components/next/cta/cta-list-item.vue';
import CtaListBase from '@/components/next/cta/cta-list-base.vue';
import CtaProductBase from '@/components/next/cta/cta-product-base.vue';
import AccordionFaq from '@/components/next/accordion/accordion-faq.vue';
import MainVideo from '@/components/next/main-video.vue';
import {
  initialOfferCTA,
  followingOfferCTA,
  ctaPackages
} from '@/placeholder/cta';
import { mapState } from 'vuex';

export default {
  components: {
    MainVideo,
    ButtonRegular,
    AccordionFaq,
    CtaProductBase,
    CtaListItem,
    CtaListBase,
    CtaBase,
    SectionBase
  },

  data: () => ({
    initialOfferCTA,
    followingOfferCTA,
    ctaPackages,
    publicPath: process.env.BASE_URL
  }),

  computed: {
    ...mapState('user', ['offer', 'offerType']),

    text() {
      let text;
      let packages = '';
      this.showSmallKWH.forEach(item => {
        packages += item + ' ';
      });

      if (this.offerType === 'initial') {
        text = {
          titleH1: `Guten Tag ${this.offer?.user?.Vorname} ${this.offer?.user?.Nachname},`,
          titleH2: 'wir haben ein ganz besonderes Angebot für Sie',
          titleCta:
            'Kaufen Sie Strompakete zu günstigen Festpreisen und schützen Sie sich so vor zukünftigen Preiserhöhungen – egal wie lange Sie benötigen, um diesen Strom zu verbrauchen.',
          listCta: initialOfferCTA,
          buttonCta: 'Mehr erfahren',
          packagesOneTitle: 'Ihr individuelles Paket',
          packagesTitle: 'Wählen Sie einfach Ihr individuelles Paket',
          packagesSubtitle:
            'Basierend auf Ihren Verbrauchsdaten haben wir folgende Pakete für Sie berechnet.',
          footnote3: `Rechnerischer Arbeitspreis bei Einmalzahlung je Paket: ${packages}Die Arbeitspreisangaben beruhen auf den angegebenen
          Verbrauchsmonaten der Pakete. <br> Alle Preisangaben verstehen sich inkl. der geltenden gesetzlichen MwSt. und sind kaufmännisch gerundet.`
        };
      } else {
        text = {
          titleH1: 'Willkommen zurück,',
          titleH2: `${this.offer?.user?.Vorname} ${this.offer?.user?.Nachname}`,
          titleCta: ` Schön, dass Sie wieder hier sind! Um auch weiterhin güstigen Ökostrom nutzen zu können, entscheiden Sie sich bitte für eines der folgenden Angebote für Ihr nächstes Strompaket.<br> <br> Damit profitieren Sie auch weiterhin von:`,
          listCta: followingOfferCTA,
          buttonCta: 'Zum neuen Angebot',
          packagesOneTitle: 'Ihr nächstes individuelles Paket',
          packagesTitle: 'Wählen Sie einfach Ihr nächstes individuelles Paket',
          packagesSubtitle:
            'Basierend auf Ihren Verbrauchsdaten haben wir folgende alternative Angebote für Sie erstellt. <br>\n' +
            '          Die vorhandene Restmenge Ihres bisherigen Strompaketes bleibt Ihnen selbstverständlich  <br>\n' +
            '          erhalten - Ihr neues Strompakte wird einfach dazu gebucht.',
          footnote3: `Basierend auf der Laufzeit und Ihrem aktuellen
          Grundpreis ergibt sich ein Arbeitspreis von Paket: ${packages}Die Arbeitspreisangaben beruhen auf den angegebenen Verbrauchsmonaten der Pakete.<br>
          Alle Preisangaben inkl. geltender gesetzlicher MwSt.`
        };
      }
      return text;
    },

    showSmallKWH() {
      let packages = this.offer?.packages ?? [];
      let arr = [];
      let packageSize = 0;

      packages.forEach((item, id) => {
        arr.push(
          `${item.NameSize}: ${(item.NewAP * 100).toFixed(2)} ct/kWh${
            packages.length - 1 === id ? '.' : ','
          }`
        );

        if (item.kwhQuantity > packageSize) {
          packageSize = item.kwhQuantity;
          this.$store.commit('user/SET_BIGGEST_PACKAGE_ID', item.Id);
        }
      });

      return arr;
    }
  },

  methods: {
    getPackagesCount() {
      return Object.keys(this.offer?.packages ?? {}).length;
    },

    scrollAction() {
      const doc = document.getElementById('offer_packages');
      const y = doc.getBoundingClientRect().top + window.scrollY - 50;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  },

  created() {
    this.$store.dispatch('user/GET_OFFER_DATA', {
      offer: this.$route.params.uuid,
      push: false
    });
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
